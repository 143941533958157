<template>
  <div class="yimiao">
    <div>
      <div style="margin-bottom: 100px;">
        <van-cell-group>
          <van-field v-model="datafrom.RoomCode"
            required
            label="房号"
            placeholder="请输入房号" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.RoomTypeDesc"
            label="房型"
            required
            placeholder="请选择房型"
            @click="roomTypePicker = true"
            readonly="readonly" />
        </van-cell-group>
        <van-popup v-model="roomTypePicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="roomTypeList"
            value-key="Name"
            @confirm="roomTypeQr"
            @cancel="roomTypePicker = false" />
        </van-popup>
        <!-- <van-cell-group>
          <van-field v-model="datafrom.UnitPrice"
            label="单价"
            required
            placeholder="请输入单价" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.Introduce"
            label="房间介绍"
            placeholder="请输入房间介绍" />
        </van-cell-group> -->
        <div class="filtersBox">
          <div class="labelClass"
            style="padding: 10px 6px 10px 0px;">

            <div>
              <span style="color:red;">* </span>预订
            </div>
          </div>
          <div style="padding: 10px 14px 6px 5px;">
            <van-radio-group v-model="datafrom.PreRoom"
              direction="horizontal">
              <van-radio name="1"
                class="radio">允许</van-radio>
              <van-radio name="2"
                class="radio">不允许</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="filtersBox">
          <div class="labelClass"
            style="padding: 10px 6px 10px 0px;">

            <div>
              <span style="color:red;">* </span>状态
            </div>
          </div>
          <div style="padding: 10px 14px 6px 5px;">
            <van-radio-group v-model="datafrom.Status"
              direction="horizontal">
              <van-radio name="1"
                class="radio">空闲</van-radio>
              <van-radio name="2"
                class="radio">在住</van-radio>
              <van-radio name="9"
                class="radio">禁用</van-radio>
            </van-radio-group>
          </div>
        </div>

        <van-cell-group>
          <van-field v-model="datafrom.Remark"
            label="备注"
            placeholder="请输入备注" />
        </van-cell-group>

        <!-- <div class="submit"
          style="padding: 10px 7px">
          <span style="color: #646566; font-size: 14px;padding-left: 10px;">照片</span>
        </div>
        <van-uploader v-model="ShopImgList"
          multiple
          capture="camera"
          class="imgs"
          :max-count="9"
          :after-read="ShopImgRead"
          :before-delete="xcDelete"
          :deletable="true" /> -->

      </div>
      <div style="position: fixed;bottom: 15px;width:100%;">
        <div style="width:100%; text-align: center;">
          <van-button class="button"
            @click="postfrom(1)"
            style="width:95%;border-radius: 5px;"
            type="info">提交</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
import {
  HGetMyHotelRoom,
  HSaveHotelRoom,
  HGetMyRoomTypeList,
} from "@/api/RealInfo";
Vue.use(Toast);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      ocodelist: [],
      datafrom: {
        RoomId: 0, //房间Id
        HotelId: this.$route.params.hotelId,//民宿旅馆Id
        RoomCode: "", // 房号
        RoomType: "", // 房型
        RoomTypeDesc: "", // 房型
        UnitPrice: "", // 单价
        Introduce: "", // 房间介绍
        PreRoom: "",//预订
        Imgs: [], // 房间照片
        Status: "", // 状态
        Remark: "", // 备注
      },
      ImgsLists: [],
      ShopImgList: [],
      roomTypeList: [],
      roomTypePicker: false,
    };
  },
  created () {
    this.getMyRoomTypeList()
  },
  methods: {
    // 获取房屋类型
    getMyRoomTypeList () {
      HGetMyRoomTypeList({
        openID: getOpenId(),
        hotelId: this.$route.params.hotelId,
      }).then((res) => {
        if (res.data.code == 0) {
          this.roomTypeList = res.data.data
          this.getHotelRoom();
        }
      })
    },
    roomType (val) {
      this.roomTypeList.forEach(e => {
        if (val == e.TypeId) {
          // console.log(e.Name);
          return e.Name
        }
      });
    },
    // 民宿类型确认
    roomTypeQr (val) {
      this.datafrom.TypeId = val.TypeId;
      this.datafrom.RoomTypeDesc = val.Name;
      this.roomTypePicker = false;
    },
    getHotelRoom: function () {
      // console.log(this.$route);
      if (this.$route.params.add == "0") {
        this.datafrom = {
          RoomId: 0, //房间Id
          HotelId: Number(this.$route.params.hotelId),//民宿旅馆Id
          RoomCode: "", // 房号
          RoomType: "", // 房型
          RoomTypeDesc: "", // 房型
          PreRoom: "",//预订
          // UnitPrice: "", // 单价
          // Introduce: "", // 房间介绍
          // Imgs: [], // 房间照片
          Status: "", // 状态
          Remark: "", // 备注
        };
      } else if (this.$route.params.add == "1") {
        HGetMyHotelRoom({
          openID: getOpenId(),
          hotelId: this.$route.params.hotelId,
          roomId: this.$route.params.roomId,
        }).then((res) => {
          if (res.data.code == 0) {
            this.datafrom = res.data.data;
            this.datafrom.PreRoom = res.data.data.PreRoom + "";
            this.datafrom.Status = res.data.data.Status + "";
            // console.log(res.data.data);
            this.roomTypeList.forEach(e => {
              if (res.data.data.TypeId == e.TypeId) {
                this.datafrom.RoomTypeDesc = e.Name
              }
            });
            // this.datafrom.RoomTypeDesc = this.roomType(this.datafrom.TypeId)
            // console.log(this.datafrom);
            //照片
            if (res.data.data.Imgs != "" && res.data.data.Imgs != null) {
              this.ImgsLists = res.data.data.Imgs.split(',');
              var arr = [];
              for (let i = 0; i < this.ImgsLists.length; i++) {
                const e = this.ImgsLists[i];
                arr.push({ url: e });
              }

              this.ShopImgList = arr;
              console.log(this.ShopImgList);
              this.datafrom.Imgs = res.data.data.Imgs;
            }
            // this.datafrom.ShopTypeKind = res.data.data.ShopTypeKind;
            this.datafrom.Region = res.data.data.RName;
          }
        });
      }
    },
    //上传民宿图片
    ShopImgRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.picurl[0]);
              // that.datafrom.Imgs.push(res.data.picurl[0]);
              that.ImgsLists.push(res.data.picurl[0]);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除商铺图片
    xcDelete (file, detail) {
      console.log(file, detail);
      this.ShopImgList.splice(detail.index, 1);
      this.ImgsLists.splice(detail.index, 1);
      // this.ShopImgList = [];
      // this.datafrom.Imgs = "";
    },
    // 提交
    postfrom: function (row) {
      if (this.datafrom.RoomCode == "") {
        Toast.fail("请输入房号");
        return false;
      }
      if (this.datafrom.RoomTypeDesc == "") {
        Toast.fail("请选择房型");
        return false;
      }
      if (this.datafrom.PreRoom == "") {
        Toast.fail("请选择是否允许预订");
        return false;
      }
      if (this.datafrom.Status == "") {
        Toast.fail("请选择状态");
        return false;
      }
      this.datafrom.OpenID = getOpenId();
      // this.datafrom.Imgs = this.ImgsLists + '';

      HSaveHotelRoom(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.go(-1);
            // this.$router.push({
            //   name: "register",
            // });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });

    },
    fanhui: function () {
      this.$router.go(-1);
    },
    // 状态选择器
    onsexshow (val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
    },



  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}

.yimiao .inputFrom {
  margin: 15px 8%;
}

.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}

.yimiao .buttons {
  text-align: center;
}

.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}

.buttons {
  text-align: center;
}

.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
.labelClass {
  font-size: 14px;
  font-weight: 500;
  width: 86px;
  color: #646566 !important;
}
.radio {
  font-size: 14px;
  color: #646566 !important;
}
.filtersBox {
  display: flex;
  border-bottom: 1px solid #ebedf085;
  margin: 0px 10px;
}
</style>